import { Box, Popover, Skeleton } from "@mui/material";
import {
    getParameterResponseOptions,
    isDefined,
} from "@convin/utils/helper/common.helper";
import BlockSettingSvg from "../../svg/BlockSettingSvg";
import useAnchorEl from "@convin/hooks/useAnchorEl";
import CreateSingleResponseBlockType from "../SingleResponse/CreateSingleResponseBlockType";
import BertCardWrapper from "../BertCardWrapper";
import BertMoreOptionsSvg from "../../svg/BertMoreOptionsSvg";
import EditLegendSvg from "../../svg/EditLegendSvg";
import DeleteLegendSvg from "../../svg/DeleteLegendSvg";
import NotOperatorLegendSvg from "../../svg/NotOperatorLegendSvg";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import NodeStatePorvider from "../../context/NodeStatePorvider";
import AttributeBlock from "../Blocks/AttributeBlock";
import ParameterBlock from "../Blocks/ParameterBlock";
import MomentsBlock from "../Blocks/MomentsBlock";
import PredefinedFuncBlock from "../Blocks/PredefinedFuncBlock";
import {
    MomentReferenceType,
    MultiResponseBlockData,
    MultipleResponseNode,
} from "../../../types/bert";
import GroupBlockNodeSvg from "../../svg/GroupBlockNodeSvg";
import SingleBlockNodeSvg from "../../svg/SingleBlockNodeSvg";
import { Label } from "@convin/components/custom_components/Typography/Label";
import { Node } from "reactflow";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import { useGetMomentsQuery } from "@convin/redux/services/settings/auditAiConfig.service";
import InfoLegendSvg from "../../svg/InfoLegendSvg";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";
import ValidateInfoBlock from "../Blocks/ValidateInfoBlock";
import { MultiResponseNodeType } from "../../context/NodeStateContext";

export default function MultiResponseBlock({
    data,
    id,
    parent,
    isConditionBlock,
    sourcePosition,
    hideDelete,
    hideNot,
}: MultiResponseBlockData<MultiResponseNodeType>["blocks"][0] & {
    isConditionBlock?: boolean;
    hideDelete?: boolean;
    hideNot?: boolean;
}) {
    const { question } = useParameterAiConfigContext();
    const { data: momentsObj, isLoading } = useGetMomentsQuery();
    const { setNodes, setEdges } = useReactFlowStateContextProvider();
    const {
        dispatch,
        aiConfigState: { activeGroupBlock },
    } = useParameterAiConfigContext();
    const { open, anchorEl, handleClick, handleClose } =
        useAnchorEl<HTMLButtonElement | null>();

    const {
        open: toolBarOpen,
        handleClick: handleToolbarClick,
        handleClose: handleToolbarClose,
        anchorEl: toolbarAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();

    const {
        open: editOpen,
        anchorEl: editAnchor,
        handleClick: handleEditClick,
        handleClose: handleEditClose,
    } = useAnchorEl<HTMLButtonElement | null>();
    const {
        open: infoOpen,
        handleClick: handleInfoClick,
        handleClose: handleInfoClose,
        anchorEl: infoAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();

    const parameterOptions = isConditionBlock
        ? []
        : getParameterResponseOptions(question);

    const isMommentBlock = data.type === "moment";
    const momentName = isMommentBlock
        ? momentsObj?.moments.find(
              (e) => e.id === (data.metadata as MomentReferenceType).id
          )?.name
        : "";

    return (
        <>
            <Box
                className="flex relative font-semibold items-center justify-between"
                gap={0.5}
                sx={(theme) => ({
                    p: 1,
                    borderRadius: "4px",
                    ...(data.is_not && {
                        border: "1px solid",
                        borderColor: theme.palette.ruleEngineColors.not,
                        borderBottomWidth: "5px",
                    }),
                    "::after": {
                        content: "''",
                        border: "1px solid",
                        borderColor:
                            isDefined(id) && id === activeGroupBlock?.blockId
                                ? "primary.main"
                                : data.is_not
                                ? "transparent"
                                : "divider",

                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        top: 0,
                        left: 1,
                        borderRadius: "inherit",
                        transform: "translate(-1px,-1px)",
                    },

                    "*": {
                        boxSizing: "border-box",
                    },
                    ...(!isDefined(data.type) && {
                        pl: 1.5,
                    }),
                })}
            >
                {isDefined(data.type) && (
                    <Box className="flex-shrink-0">
                        {data.type === "group_block" ? (
                            <GroupBlockNodeSvg />
                        ) : (
                            <SingleBlockNodeSvg />
                        )}
                        {data.is_not && (
                            <NotOperatorLegendSvg
                                sx={{
                                    transform: "scale(0.85)",

                                    display: "inline",
                                }}
                            />
                        )}
                    </Box>
                )}
                <Label
                    variant="small"
                    colorType="333"
                    className="font-semibold flex-1"
                    isEllipses
                    hasTooltip
                >
                    {isConditionBlock ? (
                        isMommentBlock ? (
                            isLoading ? (
                                <Skeleton />
                            ) : (
                                momentName
                            )
                        ) : (
                            data.metadata.name
                        )
                    ) : (
                        parameterOptions.find((e) => e.id == id)?.label
                    )}
                </Label>
                <Box
                    className="flex-shrink-0 nopan nodrag cursor-pointer"
                    sx={{
                        mr: isDefined(data.type) ? 2 : 1.2,
                    }}
                >
                    {isDefined(data.type) ? (
                        <button onClick={handleToolbarClick} className="w-full">
                            <BertMoreOptionsSvg />
                        </button>
                    ) : (
                        <>
                            <Box
                                component="button"
                                className="w-full"
                                onClick={handleClick}
                            >
                                <BlockSettingSvg />
                            </Box>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left",
                                }}
                                classes={{
                                    paper: "overflow-visible bg-transparent",
                                }}
                            >
                                <Box
                                    className="relative"
                                    sx={{
                                        width: "420px",
                                    }}
                                >
                                    <BertCardWrapper
                                        sx={{
                                            bottom: "0",
                                            left: "0",
                                            transform: "translate(0%,50%)",
                                            position: "absolute",
                                        }}
                                    >
                                        <CreateSingleResponseBlockType
                                            onNodeSaveCallBack={() => {
                                                handleClose();
                                            }}
                                            sourceId={id}
                                            nodeDataToUpdate={data.metadata}
                                            blockParentId={parent ?? undefined}
                                        />
                                    </BertCardWrapper>
                                </Box>
                            </Popover>
                        </>
                    )}
                </Box>

                <Popover
                    anchorEl={toolbarAnchor}
                    open={toolBarOpen}
                    onClose={handleToolbarClose}
                    transformOrigin={{ horizontal: -40, vertical: "center" }}
                    anchorOrigin={{ horizontal: "right", vertical: "center" }}
                    slotProps={{
                        paper: {
                            sx: {
                                bgcolor: "transparent",
                                border: "none",
                            },
                        },
                    }}
                >
                    <Box className="flex items-center flex-col" gap={1}>
                        {data.type !== "group_block" && (
                            <button onClick={handleInfoClick}>
                                <InfoLegendSvg />
                            </button>
                        )}

                        <button
                            onClick={(e) => {
                                if (data.type === "group_block") {
                                    dispatch({
                                        type: "SET_ACTIVE_GROUP_BLOCK_ID",
                                        payload: {
                                            blockId: id,
                                            blockParentId: parent!,
                                        },
                                    });
                                } else {
                                    handleEditClick(e);
                                }
                            }}
                        >
                            <EditLegendSvg />
                        </button>
                        {!hideDelete && (
                            <DeleteButton
                                onDelete={() => {
                                    return new Promise(() => {
                                        setNodes((nodes) =>
                                            nodes.map(
                                                (
                                                    node: Node<
                                                        MultipleResponseNode<MultiResponseNodeType>
                                                    >
                                                ) =>
                                                    node.id === parent
                                                        ? {
                                                              ...node,
                                                              data: {
                                                                  ...node.data,
                                                                  metadata: {
                                                                      ...node
                                                                          .data
                                                                          .metadata,
                                                                      blocks: node.data.metadata.blocks.filter(
                                                                          (b) =>
                                                                              b.id !==
                                                                              id
                                                                      ),
                                                                  },
                                                              },
                                                          }
                                                        : node
                                            )
                                        );
                                        setEdges((edges) =>
                                            edges.filter(
                                                (e) =>
                                                    e.sourceHandle !==
                                                    `${id}_${sourcePosition}`
                                            )
                                        );

                                        handleToolbarClose();
                                    });
                                }}
                                message="Are you sure you want to delete the block?"
                                title="Delete Block"
                                DeleteOptionComponent={
                                    <button>
                                        <DeleteLegendSvg />
                                    </button>
                                }
                            />
                        )}
                        {!hideNot && (
                            <button
                                onClick={() => {
                                    setNodes((nodes) =>
                                        nodes.map(
                                            (
                                                node: Node<
                                                    MultipleResponseNode<MultiResponseNodeType>
                                                >
                                            ) =>
                                                node.id === parent
                                                    ? {
                                                          ...node,
                                                          data: {
                                                              ...node.data,
                                                              metadata: {
                                                                  ...node.data
                                                                      .metadata,
                                                                  blocks: node.data.metadata.blocks.map(
                                                                      (b) =>
                                                                          b.id ===
                                                                          id
                                                                              ? {
                                                                                    ...b,
                                                                                    data: {
                                                                                        ...b.data,
                                                                                        is_not: !b
                                                                                            .data
                                                                                            .is_not,
                                                                                    },
                                                                                }
                                                                              : b
                                                                  ),
                                                              },
                                                          },
                                                      }
                                                    : node
                                        )
                                    );
                                    handleToolbarClose();
                                }}
                            >
                                <NotOperatorLegendSvg />
                            </button>
                        )}
                    </Box>
                </Popover>
                <Popover
                    open={editOpen || infoOpen}
                    anchorEl={editAnchor || infoAnchor}
                    onClose={editOpen ? handleEditClose : handleInfoClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    sx={{
                        ".MuiPaper-root": {
                            mt: 0,
                        },
                    }}
                >
                    <NodeStatePorvider
                        onNodeSaveCallBack={() => {
                            handleToolbarClose();
                            handleEditClose();
                        }}
                        nodeIdToUpdate={id}
                        nodeDataToUpdate={data.metadata}
                        blockParentId={parent!}
                    >
                        <BertCardWrapper
                            className="w-[400px]"
                            sx={{
                                mt: 2,
                                ...((infoOpen || !editOpen) && {
                                    "& button": {
                                        display: "none",
                                    },

                                    "& *": {
                                        userSelect: "none",
                                        pointerEvents: "none",
                                    },
                                }),
                            }}
                        >
                            {data.type === "attribute" ? (
                                <AttributeBlock />
                            ) : (
                                <></>
                            )}
                            {data.type === "parameter" ? (
                                <ParameterBlock />
                            ) : (
                                <></>
                            )}
                            {data.type === "moment" ? <MomentsBlock /> : <></>}
                            {data.type === "function" ? (
                                <PredefinedFuncBlock />
                            ) : (
                                <></>
                            )}
                            {data.type === "validateInfo" ? (
                                <ValidateInfoBlock />
                            ) : (
                                <></>
                            )}
                        </BertCardWrapper>
                    </NodeStatePorvider>
                </Popover>
            </Box>
        </>
    );
}
