import { Box, Stack, Typography } from "@mui/material";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFTextField } from "@convin/components/hook-form";
import { useReactFlowStateContextProvider } from "../hooks/useReactFlowStateContextProvider";
import type {
    ConditionBlockSingleResponseNode,
    MomentReferenceType,
    MultiResponseBlockData,
    MultipleResponseNode,
} from "../../types/bert";
import BlockHeader from "./Blocks/BlockHeader";
import useNodeContext from "../hooks/useNodeContext";
import {
    generateCleanNanoID,
    isDefined,
} from "@convin/utils/helper/common.helper";
import { CloseSvg } from "@convin/components/svg";
import { useGetMomentsQuery } from "@convin/redux/services/settings/auditAiConfig.service";
import {
    MultiResponseNodeType,
    SingleResponseBlockType,
} from "../context/NodeStateContext";

export default function CreateEditConditionalNode({
    type,
}: {
    type: Exclude<MultiResponseNodeType, "response">;
}) {
    const { data: momentsObj } = useGetMomentsQuery();
    const { createMultipleResponseNode, updateMultipleResponseNode } =
        useReactFlowStateContextProvider();
    const {
        sourceId,
        direction,
        onNodeSaveCallBack,
        nodeDataToUpdate,
        nodeIdToUpdate,
        blockId,
    } = useNodeContext();
    const schema = yup.object().shape({
        name: yup.string().required("Name is Required"),
        prompt:
            type === "condition_gpt"
                ? yup.string().required("Prompt is Required")
                : yup.string().nullable(),
        blocks: yup.array().of(
            yup.object().shape({
                isNew: yup.boolean(),
                data: yup.object().shape({
                    type: yup.string().nullable(),
                    metadata: yup.object().shape({
                        name: yup.string().required("Mandatory Field"),
                    }),
                }),
            })
        ),
    });

    const methods = useForm<MultiResponseBlockData<typeof type>>({
        resolver: yupResolver(schema),
        ...(isDefined(nodeDataToUpdate)
            ? {
                  values: {
                      ...(nodeDataToUpdate as MultiResponseBlockData<
                          typeof type
                      >),
                      blocks: (
                          nodeDataToUpdate as MultiResponseBlockData<
                              typeof type
                          >
                      ).blocks.map((e) => {
                          const { isNew: _, ...rest } = e;
                          const data =
                              e.data as ConditionBlockSingleResponseNode<SingleResponseBlockType>;
                          //You have to remove the name for moment type as moment is referenced (i.e it is created in backend)
                          return data.type === "moment"
                              ? {
                                    ...rest,
                                    data: {
                                        ...data,
                                        type: "moment",
                                        metadata: {
                                            ...data.metadata,
                                            name: momentsObj?.moments.find(
                                                (m) =>
                                                    m.id ===
                                                    (
                                                        data.metadata as MomentReferenceType
                                                    ).id
                                            )?.name,
                                        },
                                    },
                                }
                              : rest;
                      }),
                  },
              }
            : {
                  defaultValues: {
                      name: "",
                      ...(type === "condition_gpt" && { prompt: "" }),
                      blocks: [
                          {
                              id: generateCleanNanoID(),
                              isNew: true,
                              data: {
                                  type: null,

                                  metadata: {
                                      name: "Block 1",
                                  },
                              },
                          },
                          {
                              id: generateCleanNanoID(),
                              isNew: true,
                              data: {
                                  type: null,

                                  metadata: {
                                      name: "Block 2",
                                  },
                              },
                          },
                      ],
                  },
              }),
    });

    const { control, handleSubmit } = methods;

    const { fields, append, remove } = useFieldArray<
        MultiResponseBlockData<typeof type>,
        "blocks",
        "id"
    >({
        control,
        name: "blocks",
    });

    const onSubmit = async (payload: MultiResponseBlockData<typeof type>) => {
        if (isDefined(nodeIdToUpdate)) {
            updateMultipleResponseNode({
                sourceId: nodeIdToUpdate,
                data: {
                    ...payload,
                    blocks: payload.blocks.map(({ isNew: _, ...e }) => {
                        const data =
                            e.data as ConditionBlockSingleResponseNode<SingleResponseBlockType>;

                        return data.type === "moment"
                            ? {
                                  ...e,
                                  data: {
                                      ...data,
                                      type: "moment",
                                      //You have to remove the name for moment type as moment is referenced (i.e it is created in backend)
                                      metadata: {
                                          ...data.metadata,
                                          name: "",
                                      },
                                  },
                              }
                            : e;
                    }),
                },
            });
        } else
            createMultipleResponseNode<
                MultipleResponseNode<Exclude<MultiResponseNodeType, "response">>
            >({
                data: {
                    type: type,
                    metadata: payload,
                },
                sourceId,
                direction,
                blockId,
            });
        onNodeSaveCallBack();
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box className="w-full max-h-[350px] flex flex-col">
                <BlockHeader
                    title={
                        type === "condition"
                            ? "Conditional Block"
                            : "GPT CONDITION"
                    }
                    showCreateButton
                />
                <Stack
                    className="w-full flex-1 overflow-y-auto nowheel"
                    py={2.5}
                    gap={2}
                >
                    <RHFTextField
                        name="name"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        placeholder="Block Name"
                    />
                    {type === "condition_gpt" && (
                        <RHFTextField
                            name="prompt"
                            className="w-full"
                            variant="outlined"
                            size="small"
                            placeholder="Prompt"
                            label="Prompt"
                            multiline
                            rows={4}
                        />
                    )}
                    {fields.map((e, index) => {
                        return (
                            <Box key={e.id} className="flex items-start">
                                <RHFTextField
                                    name={`blocks.${index}.data.metadata.name`}
                                    className="flex-1"
                                    variant="outlined"
                                    size="small"
                                    placeholder={`Block Name ${index + 1}`}
                                    disabled={!e.isNew}
                                />
                                {!isDefined(e?.data?.type) &&
                                    fields.length > 2 && (
                                        <button
                                            className="flex-shrink-0 ml-2 mt-2"
                                            onClick={() => {
                                                remove(index);
                                            }}
                                        >
                                            <CloseSvg />
                                        </button>
                                    )}
                            </Box>
                        );
                    })}
                </Stack>
                <Box>
                    <Typography
                        className="flex-shrink-0 font-semibold"
                        variant="small"
                        color="primary"
                        component="button"
                        onClick={() => {
                            append({
                                id: generateCleanNanoID(),
                                parent: null,
                                sourcePosition: null,
                                isNew: true,
                                data: {
                                    type: null,
                                    is_not: false,
                                    metadata: {
                                        name: "",
                                    },
                                },
                            });
                        }}
                    >
                        add more condition
                    </Typography>
                </Box>
            </Box>
        </FormProvider>
    );
}
