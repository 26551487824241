import { alpha, Box, Divider, Paper, Stack, Typography } from "@mui/material";
import BlockHeader from "./BlockHeader";
import {
    FormProvider,
    RHFSelect,
    RHFTextField,
} from "@convin/components/hook-form";
import { useMemo, useState } from "react";
import * as Yup from "yup";
import { Controller, useForm, useFormContext } from "react-hook-form";
import {
    SingleResponseNode,
    ValidateInfoBlockFormType,
} from "../../../types/bert";
import { isDefined } from "@convin/utils/helper/common.helper";
import useNodeContext from "../../hooks/useNodeContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { bordelessVariariantProps } from "@convin/components/select/GenericSelect";
import { useGetCustomEntitiesQuery } from "@convin/redux/services/settings/entity.service";
import RHFSelectCustom from "@convin/components/hook-form/RHFSelectCustom";
import {
    useGetAttributeCategoriesQuery,
    useGetAttributeCategoryKeysQuery,
    useGetDataTypeOperatorsQuery,
} from "@convin/redux/services/settings/auditAiConfig.service";
import { useGetAttributeCategoryKeysList } from "../../hooks/useGetAttributeCategoryKeysList";
import InfiniteScrollSelect from "@convin/components/select/InfiniteScrollSelect";
import useDebounce from "@hooks/useDebounce";
import { EntityField } from "@convin/type/Entity";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import dayjs from "dayjs";
import InputValue from "./components/InputValue";
import CustomSkeletonLoader from "@convin/components/custom_components/Skeleton";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import InfoSvg from "@convin/components/svg/InfoSvg";

const styleProps = bordelessVariariantProps({
    "& .MuiSvgIcon-root": {
        color: "primary.main",
        bgcolor: "unset",
    },
    "& .MuiTypography-root,.MuiInputBase-input": {
        color: "primary.main",
    },
});

const validateOptions: Array<{
    id: NonNullable<ValidateInfoBlockFormType["validate"]>;
    label: string;
}> = [
    { id: "entity", label: "Entity" },
    {
        id: "attribute_category",
        label: "Attribute Category",
    },
    {
        id: "static_info",
        label: "Static Information",
    },
];

const relativeOptions: Array<{
    id: "+" | "-" | "+/-";
    label: string;
}> = [
    { id: "-", label: "Before" },
    { id: "+", label: "After" },
    { id: "+/-", label: "Nearby" },
];

const operatorMap: Record<
    NonNullable<ValidateInfoBlockFormType["operator"]>,
    string
> = {
    lt: "Less Than",
    gt: "Greater Than",
    lte: "Less Than or Equal To",
    gte: "Greater Than or Equal To",
    between: "Between",
    same_day: "Same Day",
    same_month: "Same Month",
    same_year: "Same Year",
    equals: "Equals",
    isTrue: "Is True",
    isFalse: "Is False",
    contains: "Contains",
    not_contains: "Not Contains",
    starts_with: "Starts With",
    ends_with: "Ends With",
    not_equals: "Not Equals",
};

const formatDataToUpdate = (
    e: ValidateInfoBlockFormType
): ValidateInfoBlockFormType => {
    //Check is important because the below fields exist only for static info type
    if (e.validateWith !== "static_info") return e;
    const { value, min, max, operator, dataType } = e;
    const data = {
        ...e,
        value:
            dataType === "date" &&
            isDefined(operator) &&
            !["same_day", "same_month", "same_year"].includes(operator)
                ? dayjs.unix(Number(value)).toDate()
                : value,
        min:
            dataType === "date" && operator === "between"
                ? dayjs.unix(Number(min)).toDate()
                : min,
        max:
            dataType === "date" && operator === "between"
                ? dayjs.unix(Number(max)).toDate()
                : max,
    };

    return data;
};

const ValidationField = ({
    name,
}: {
    name: keyof Pick<ValidateInfoBlockFormType, "validate" | "validateWith">;
}) => {
    const { toggleDrawerState } = useParameterAiConfigContext();
    const { watch, setValue } = useFormContext<ValidateInfoBlockFormType>();
    const { data } = useGetDataTypeOperatorsQuery();
    const { data: categories, isFetching: isLoadingCategories } =
        useGetAttributeCategoriesQuery();
    const { data: entities, isFetching: isLoadingEntitites } =
        useGetCustomEntitiesQuery();
    const operator = watch("operator");
    const attributeCategory = watch("attributeCategory");
    const attributeCategoryKey = watch("attributeCategoryKey");
    const dataType = watch("dataType");
    const { data: selectedKey, isLoading: isLoadingSlectedKey } =
        useGetAttributeCategoryKeysQuery(
            {
                id: Number(attributeCategoryKey!),
            },
            {
                skip: !isDefined(attributeCategoryKey),
            }
        );
    const entity = entities?.find((e) => e.id === watch("entity"));

    const [query, setQuery] = useState("");

    const debouncedQuery = useDebounce(query, 1000);

    const {
        data: keys,
        isFetching,
        isLoading,
        hasNext,
        fetchNext,
    } = useGetAttributeCategoryKeysList(
        {
            metadata_type: attributeCategory!,
            query: debouncedQuery,
            ...(isDefined(entity) && {
                data_type: entity.data_type,
            }),
        },
        undefined,
        {
            skip: !isDefined(attributeCategory),
            refetchOnMountOrArgChange: true,
        }
    );
    const validateType = watch(name);
    const validateAgainst = watch(
        name === "validate" ? "validateWith" : "validate"
    );

    const resetFields = (resetOperator: boolean | undefined = true) => {
        resetOperator && setValue("operator", null);
        setValue("min", null);
        setValue("max", null);
        setValue("value", null);
        setValue("list", []);
    };

    const keyList = useMemo(() => {
        if (keys?.results.find((e) => e.id === selectedKey?.results[0]?.id)) {
            return keys.results;
        } else
            return [...(keys?.results ?? []), ...(selectedKey?.results ?? [])];
    }, [selectedKey, keys]);

    if (isLoadingCategories || isLoadingEntitites) {
        return <CustomSkeletonLoader rows={2} />;
    }

    return (
        <>
            <Box className="flex">
                {name === "validateWith" &&
                dataType === "boolean" &&
                (operator === "isTrue" || operator === "isFalse") ? (
                    <></>
                ) : (
                    <RHFSelect<
                        NonNullable<ValidateInfoBlockFormType["validate"]>
                    >
                        label=""
                        options={validateOptions.filter((e) =>
                            name === "validate"
                                ? e.id !== "static_info" &&
                                  e.id !== validateAgainst
                                : dataType === "boolean" &&
                                  operator === "equals"
                                ? e.id !== "static_info" &&
                                  e.id !== validateAgainst
                                : e.id !== validateAgainst
                        )}
                        name={name}
                        {...styleProps}
                        width="fit"
                        className="nodrag nopan"
                        onChangeCallback={(id) => {
                            if (name === "validate") {
                                setValue("dataType", null);
                                setValue("entity", null);
                                setValue("attributeCategory", null);
                                setValue("attributeCategoryKey", null);
                                resetFields();
                            }
                            if (
                                id === "attribute_category" &&
                                name === "validate"
                            ) {
                                setValue("entity", null);
                                resetFields();
                            }
                            if (id === "entity" && name === "validate") {
                                setValue("attributeCategory", null);
                                setValue("attributeCategoryKey", null);
                                resetFields();
                            }
                            if (
                                (id === "attribute_category" ||
                                    id === "entity") &&
                                name === "validateWith"
                            ) {
                                resetFields(operator === "between");
                            }
                        }}
                        placeholder="Select"
                        disabled={
                            name === "validateWith" && !isDefined(operator)
                        }
                    />
                )}
            </Box>
            {isDefined(validateType) ? (
                validateType === "entity" ? (
                    <>
                        <RHFSelectCustom<{ id: number; label: string }>
                            name="entity"
                            label="Choose Entity"
                            autocompleteProps={{
                                options:
                                    entities
                                        ?.filter((e) => !e.is_disabled)
                                        ?.filter((e) =>
                                            name === "validateWith"
                                                ? e.data_type === dataType
                                                : true
                                        )
                                        .map((e) => ({
                                            id: e.id,
                                            label: e.name,
                                        })) ?? [],
                                size: "small",
                                PaperComponent: ({ children }) => {
                                    return (
                                        <Paper
                                            sx={(theme) => ({
                                                border: "1px solid",
                                                borderColor: alpha(
                                                    theme.palette.textColors[
                                                        "333"
                                                    ],
                                                    0.1
                                                ),
                                            })}
                                        >
                                            <Box
                                                p={2}
                                                gap={1}
                                                onMouseDown={(event) =>
                                                    event.preventDefault()
                                                }
                                                color="primary.main"
                                                fontWeight={600}
                                                component="button"
                                                onClick={() => {
                                                    toggleDrawerState(
                                                        "isEntityModalOpen"
                                                    );
                                                }}
                                            >
                                                + Create Entity
                                            </Box>
                                            {children}
                                        </Paper>
                                    );
                                },
                                loading:
                                    isLoadingEntitites || isLoadingSlectedKey,
                                disabled:
                                    name === "validateWith" &&
                                    !isDefined(dataType),
                            }}
                            onChangeCallback={(id) => {
                                if (name === "validate") {
                                    const entity = entities?.find(
                                        (e) => e.id === id
                                    );
                                    setValue(
                                        "dataType",
                                        entity?.data_type ?? null
                                    );
                                    setValue("attributeCategory", null);
                                    setValue("attributeCategoryKey", null);
                                    resetFields();
                                }
                            }}
                        />
                        <Box className="flex items-center" gap={1}>
                            <InfoSvg
                                sx={{
                                    color: (theme) =>
                                        theme.palette.primary.main,
                                }}
                            />
                            <Typography
                                variant="small"
                                sx={{ colors: "textColor.666" }}
                            >
                                This will run on filters applicable on the Audit
                                Template level.
                            </Typography>
                        </Box>
                        {name === "validateWith" && (
                            <Typography variant="small">
                                <Typography color="error.main" variant="small">
                                    Note:
                                </Typography>{" "}
                                The Entities are dynamically filtered based on
                                the selected Attribute Category key.
                            </Typography>
                        )}
                    </>
                ) : validateType === "attribute_category" ? (
                    <>
                        <RHFSelectCustom<{ id: number; label: string }>
                            name="attributeCategory"
                            label="Choose Attribute Category"
                            autocompleteProps={{
                                options:
                                    categories?.map((e) => ({
                                        id: e.id,
                                        label: e.name,
                                    })) ?? [],
                                size: "small",
                                loading: isLoadingCategories,
                                disabled:
                                    name === "validateWith" &&
                                    !isDefined(dataType),
                            }}
                            onChangeCallback={() => {
                                if (name === "validate") {
                                    setValue("dataType", null);
                                    setValue("entity", null);
                                    resetFields();
                                }
                                setValue("attributeCategoryKey", null);
                            }}
                        />
                        <Controller
                            name="attributeCategoryKey"
                            render={({ field, fieldState: { error } }) => {
                                return (
                                    <InfiniteScrollSelect
                                        label="Choose Key"
                                        options={
                                            keyList?.map((e) => ({
                                                ...e,
                                                label: e.name,
                                            })) ?? []
                                        }
                                        onQueryChange={(value, reason) => {
                                            if (reason !== "select")
                                                setQuery(value ?? "");
                                        }}
                                        value={field.value ?? undefined}
                                        setValue={(id, option) => {
                                            field.onChange(id);
                                            if (
                                                name === "validate" &&
                                                isDefined(option) &&
                                                validateType ===
                                                    "attribute_category"
                                            ) {
                                                setValue(
                                                    "dataType",
                                                    option.data_type
                                                );
                                                setValue("entity", null);
                                                resetFields();
                                            }
                                        }}
                                        className="flex-1"
                                        virtualizeProps={{
                                            fetchNext,
                                            hasNext,
                                            isFetching,
                                            isLoading,
                                        }}
                                        size="small"
                                        error={!!error}
                                        helperText={error?.message}
                                        disabled={
                                            validateType ===
                                                "attribute_category" &&
                                            !isDefined(
                                                watch("attributeCategory")
                                            )
                                        }
                                    />
                                );
                            }}
                        />
                        {name === "validateWith" && (
                            <Typography variant="small">
                                <Typography color="error.main" variant="small">
                                    Note:
                                </Typography>{" "}
                                The Attribute Category keys are dynamically
                                filtered based on the selected Entity.
                            </Typography>
                        )}
                    </>
                ) : (
                    <InputValue />
                )
            ) : (
                <></>
            )}

            {isDefined(validateType) && name !== "validateWith" && (
                <>
                    <Divider />
                    <Box className="flex" gap={2}>
                        <RHFSelectCustom<{ id: string; label: string }>
                            label="Select Operator"
                            autocompleteProps={{
                                options:
                                    isDefined(data) && isDefined(dataType)
                                        ? data.operators[
                                              dataType.includes("array")
                                                  ? "array"
                                                  : dataType
                                          ]
                                              ?.filter((e) =>
                                                  (validateAgainst ===
                                                      "entity" &&
                                                      validateType ===
                                                          "attribute_category") ||
                                                  (validateAgainst ===
                                                      "attribute_category" &&
                                                      validateType === "entity")
                                                      ? e !== "between"
                                                      : true
                                              )
                                              ?.map((e) => ({
                                                  id: e,
                                                  label: operatorMap[e] ?? e,
                                              }))
                                        : [],

                                size: "small",
                                disabled: !isDefined(dataType),
                                disableClearable: false,
                            }}
                            name="operator"
                            className="nodrag nopan w-full"
                            onChangeCallback={(operator) => {
                                if (dataType === "boolean") {
                                    if (
                                        operator === "isTrue" ||
                                        operator === "isFalse"
                                    ) {
                                        if (
                                            validateAgainst ===
                                            "attribute_category"
                                        ) {
                                            setValue("attributeCategory", null);
                                            setValue(
                                                "attributeCategoryKey",
                                                null
                                            );
                                        } else if (
                                            validateAgainst === "entity"
                                        ) {
                                            setValue("entity", null);
                                        } else {
                                            resetFields(false);
                                        }
                                        setValue("validateWith", null);
                                    }
                                }
                                if (!isDefined(operator)) {
                                    resetFields();
                                }
                            }}
                        />
                        {operator === "relative" && dataType === "date" && (
                            <>
                                <RHFSelectCustom
                                    name="relativeOperation"
                                    autocompleteProps={{
                                        className: "nodrag nopan",
                                        options: relativeOptions,
                                        size: "small",
                                    }}
                                    label=""
                                    placeholder="Before/After"
                                />
                                <RHFTextField
                                    name="relativeDays"
                                    placeholder="Sec"
                                    size="small"
                                    type="number"
                                />
                            </>
                        )}
                    </Box>
                </>
            )}
        </>
    );
};

export default function ValidateInfoBlock({
    showCreateButton = true,
}: {
    showCreateButton?: boolean;
}) {
    const {
        nodeDataToUpdate,
        nodeIdToUpdate,
        sourceId,
        blockParentId,
        direction,
        onNodeSaveCallBack,
    } = useNodeContext();
    const { updateSingleResponseNode, createSingleResponseNode } =
        useReactFlowStateContextProvider();
    const methods = useForm<ValidateInfoBlockFormType>({
        resolver: yupResolver(
            Yup.object().shape({
                name: Yup.string().required("Name is Required"),
                validate: Yup.string().nullable().required("Required"),
                validateWith: Yup.string()
                    .nullable()
                    .when("dataType", {
                        is: (dataType: ValidateInfoBlockFormType["dataType"]) =>
                            dataType !== "boolean",
                        then: (s) => s.required("Required"),
                        otherwise: (s) => s,
                    }),
                operator: Yup.string().nullable().required("Required"),
                dataType: Yup.string().nullable(),
                entity: Yup.number()
                    .nullable()
                    .when(["validate", "validateWith"], {
                        is: (
                            validate: ValidateInfoBlockFormType["validate"],
                            validateWith: ValidateInfoBlockFormType["validate"]
                        ) => validate === "entity" || validateWith === "entity",
                        then: (s) => s.required("Required"),
                        otherwise: (s) => s,
                    }),
                attributeCategory: Yup.number()
                    .nullable()
                    .when(["validate", "validateWith"], {
                        is: (
                            validate: ValidateInfoBlockFormType["validate"],
                            validateWith: ValidateInfoBlockFormType["validate"]
                        ) =>
                            validate === "attribute_category" ||
                            validateWith === "attribute_category",
                        then: (s) => s.required("Required"),
                        otherwise: (s) => s,
                    }),
                attributeCategoryKey: Yup.number()
                    .nullable()
                    .when(["validate", "validateWith"], {
                        is: (
                            validate: ValidateInfoBlockFormType["validate"],
                            validateWith: ValidateInfoBlockFormType["validate"]
                        ) =>
                            validate === "attribute_category" ||
                            validateWith === "attribute_category",
                        then: (s) => s.required("Required"),
                        otherwise: (s) => s,
                    }),
                value: Yup.mixed()
                    .nullable()
                    .when(["validateWith", "operator"], {
                        is: (
                            validateWith: ValidateInfoBlockFormType["validate"],
                            operator: string
                        ) =>
                            validateWith === "static_info" && operator !== "in",
                        then: (schema) =>
                            schema.when(["operator", "dataType"], {
                                is: (
                                    operator: string,
                                    dataType: EntityField["data_type"]
                                ) => {
                                    return (
                                        operator !== "between" &&
                                        operator !== "same_day" &&
                                        operator !== "same_month" &&
                                        operator !== "same_year" &&
                                        dataType === "date"
                                    );
                                },
                                then: () =>
                                    Yup.date()
                                        .nullable()
                                        .required("Date is required"),
                                otherwise: (schema) =>
                                    schema.when(["operator", "dataType"], {
                                        is: (
                                            operator: string,
                                            dataType: EntityField["data_type"]
                                        ) => {
                                            return (
                                                operator === "same_day" &&
                                                dataType === "date"
                                            );
                                        },
                                        then: () =>
                                            Yup.number()
                                                .min(1)
                                                .max(31)
                                                .typeError("Required")
                                                .required("Date is required"),
                                        otherwise: (schema) =>
                                            schema.when(
                                                ["operator", "dataType"],
                                                {
                                                    is: (
                                                        operator: string,
                                                        dataType: EntityField["data_type"]
                                                    ) => {
                                                        return (
                                                            operator ===
                                                                "same_month" &&
                                                            dataType === "date"
                                                        );
                                                    },
                                                    then: () =>
                                                        Yup.number()
                                                            .min(1)
                                                            .max(12)
                                                            .typeError(
                                                                "Required"
                                                            )
                                                            .required(
                                                                "Month is required"
                                                            ),
                                                    otherwise: (schema) =>
                                                        schema.when(
                                                            [
                                                                "operator",
                                                                "dataType",
                                                            ],
                                                            {
                                                                is: (
                                                                    operator: string,
                                                                    dataType: EntityField["data_type"]
                                                                ) => {
                                                                    return (
                                                                        operator ===
                                                                            "same_year" &&
                                                                        dataType ===
                                                                            "date"
                                                                    );
                                                                },
                                                                then: () =>
                                                                    Yup.number()
                                                                        .min(
                                                                            1970
                                                                        )
                                                                        .max(
                                                                            new Date().getFullYear()
                                                                        )
                                                                        .typeError(
                                                                            "Required"
                                                                        )
                                                                        .required(
                                                                            "Year is required"
                                                                        ),
                                                                otherwise: (
                                                                    schema
                                                                ) =>
                                                                    schema.when(
                                                                        [
                                                                            "operator",
                                                                            "dataType",
                                                                        ],
                                                                        {
                                                                            is: (
                                                                                operator: string,
                                                                                dataType: EntityField["data_type"]
                                                                            ) => {
                                                                                return (
                                                                                    operator !==
                                                                                        "between" &&
                                                                                    dataType !==
                                                                                        "date"
                                                                                );
                                                                            },
                                                                            then: (
                                                                                schema
                                                                            ) =>
                                                                                schema.required(
                                                                                    "Value is required"
                                                                                ),
                                                                            otherwise:
                                                                                (
                                                                                    schema
                                                                                ) =>
                                                                                    schema,
                                                                        }
                                                                    ),
                                                            }
                                                        ),
                                                }
                                            ),
                                    }),
                            }),
                        otherwise: (schema) => schema,
                    }),
                min: Yup.mixed()
                    .nullable()
                    .when(["validateWith", "operator"], {
                        is: (
                            validateWith: ValidateInfoBlockFormType["validate"],
                            operator: string
                        ) =>
                            validateWith === "static_info" && operator !== "in",
                        then: (schema) =>
                            schema.when(["operator", "dataType"], {
                                is: (
                                    operator: string,
                                    dataType: EntityField["data_type"]
                                ) => {
                                    return (
                                        operator === "between" &&
                                        dataType === "date"
                                    );
                                },
                                then: () =>
                                    Yup.date()
                                        .required("Date is required")
                                        .min(
                                            "2000-01-01",
                                            "Please enter a date starting from 01-01-2000"
                                        )
                                        .max(
                                            new Date(),
                                            "Date cannot be in the future"
                                        )
                                        .typeError(
                                            "Invalid date format. (mm/dd/yyyy)"
                                        )
                                        .test(
                                            "min-less-than-max",
                                            "Date must be before end date",
                                            function (value) {
                                                const { max } = this.parent;
                                                if (!max || !value) return true;
                                                return (
                                                    new Date(value) <
                                                    new Date(max)
                                                );
                                            }
                                        ),
                                otherwise: (schema) =>
                                    schema.when(["operator", "dataType"], {
                                        is: (
                                            operator: string,
                                            dataType: EntityField["data_type"]
                                        ) => {
                                            return (
                                                operator === "between" &&
                                                dataType !== "date"
                                            );
                                        },
                                        then: (schema) =>
                                            schema
                                                .required("Value is required")
                                                .test(
                                                    "min-less-than-max",
                                                    "Should be less than max value",
                                                    function (value) {
                                                        const { max } =
                                                            this.parent;
                                                        if (!max || !value)
                                                            return true;
                                                        return (
                                                            Number(value) <
                                                            Number(max)
                                                        );
                                                    }
                                                ),
                                        otherwise: (schema) => schema,
                                    }),
                            }),
                        otherwise: (schema) => schema,
                    }),
                max: Yup.mixed()
                    .nullable()
                    .when(["validateWith", "operator"], {
                        is: (
                            validateWith: ValidateInfoBlockFormType["validate"],
                            operator: string
                        ) =>
                            validateWith === "static_info" && operator !== "in",
                        then: (schema) =>
                            schema.when(["operator", "dataType"], {
                                is: (
                                    operator: string,
                                    dataType: EntityField["data_type"]
                                ) => {
                                    return (
                                        operator === "between" &&
                                        dataType === "date"
                                    );
                                },
                                then: () =>
                                    Yup.date()
                                        .required("Date is required")
                                        .min(
                                            "2000-01-01",
                                            "Please enter a date starting from 01-01-2000"
                                        )
                                        .max(
                                            new Date(),
                                            "Date cannot be in the future"
                                        )
                                        .typeError(
                                            "Invalid date format. (mm/dd/yyyy)"
                                        )
                                        .test(
                                            "max-greater-than-min",
                                            "Date must be after start date",
                                            function (value) {
                                                const { min } = this.parent;
                                                if (!min || !value) return true;
                                                return (
                                                    new Date(value) >
                                                    new Date(min)
                                                );
                                            }
                                        ),
                                otherwise: (schema) =>
                                    schema.when(["operator", "dataType"], {
                                        is: (
                                            operator: string,
                                            dataType: EntityField["data_type"]
                                        ) => {
                                            return (
                                                operator === "between" &&
                                                dataType !== "date"
                                            );
                                        },
                                        then: (schema) =>
                                            schema
                                                .required("Value is required")
                                                .test(
                                                    "max-greater-than-min",
                                                    "Should be greater than min value",
                                                    function (value) {
                                                        const { min } =
                                                            this.parent;
                                                        if (!min || !value)
                                                            return true;
                                                        return (
                                                            Number(value) >
                                                            Number(min)
                                                        );
                                                    }
                                                ),
                                        otherwise: (schema) => schema,
                                    }),
                            }),
                        otherwise: (schema) => schema,
                    }),
                list: Yup.array().when(["validateWith", "operator"], {
                    is: (
                        validateWith: ValidateInfoBlockFormType["validate"],
                        operator: string
                    ) => validateWith === "static_info" && operator === "in",
                    then: (schema) =>
                        schema.when("dataType", {
                            is: (dataType: EntityField["data_type"]) => {
                                return dataType === "array_float";
                            },
                            then: () =>
                                Yup.array()
                                    .of(Yup.number())
                                    .min(1, "Enter at least one value"),
                            otherwise: (schema) =>
                                schema.when("dataType", {
                                    is: (
                                        dataType: EntityField["data_type"]
                                    ) => {
                                        return dataType === "array_string";
                                    },
                                    then: () =>
                                        Yup.array()
                                            .of(Yup.string())
                                            .min(1, "Enter atleast one value"),
                                    otherwise: (schema) => schema,
                                }),
                        }),
                    otherwise: (schema) => schema,
                }),
                relativeDays: Yup.number()
                    .nullable()
                    .when(["operator", "dataType"], {
                        is: (
                            operator: string,
                            dataType: ValidateInfoBlockFormType["dataType"]
                        ) => operator === "relative" && dataType === "date",
                        then: (s) => s.required("Required"),
                        otherwise: (s) => s,
                    }),
                relativeOperation: Yup.string()
                    .nullable()
                    .when(["operator", "dataType"], {
                        is: (
                            operator: string,
                            dataType: ValidateInfoBlockFormType["dataType"]
                        ) => operator === "relative" && dataType === "date",
                        then: (s) => s.required("Required"),
                        otherwise: (s) => s,
                    }),
            })
        ),
        values: isDefined(nodeDataToUpdate)
            ? {
                  ...formatDataToUpdate(
                      nodeDataToUpdate as ValidateInfoBlockFormType
                  ),
              }
            : {
                  name: "",
                  validate: null,
                  validateWith: null,
                  entity: null,
                  operator: null,
                  attributeCategory: null,
                  attributeCategoryKey: null,
                  dataType: null,
                  value: null,
                  min: null,
                  max: null,
                  list: [],
                  relativeDays: null,
                  relativeOperation: null,
              },
    });

    const onSubmit = async (blockData: ValidateInfoBlockFormType) => {
        const { value, min, max, operator, dataType } = blockData;
        const data = {
            ...blockData,
            value:
                dataType === "date" &&
                !["between", "same_month", "same_day", "same_year"]?.includes(
                    operator!
                )
                    ? dayjs(value).unix()
                    : value,
            min:
                dataType === "date" && operator === "between"
                    ? dayjs(min).unix()
                    : min,
            max:
                dataType === "date" && operator === "between"
                    ? dayjs(max).unix()
                    : max,
        };
        if (isDefined(nodeIdToUpdate)) {
            updateSingleResponseNode<ValidateInfoBlockFormType>({
                data,
                sourceId: nodeIdToUpdate,
                blockParentId,
            });
        } else
            createSingleResponseNode<SingleResponseNode<"validateInfo">>({
                data: {
                    type: "validateInfo",
                    is_not: false,
                    metadata: {
                        ...data,
                    },
                },
                sourceId,
                direction,
                blockParentId,
            });
        onNodeSaveCallBack();
    };

    const { handleSubmit } = methods;

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <BlockHeader
                    title="Validate Information"
                    showCreateButton={showCreateButton}
                />
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <Stack gap={1.5}>
                        <RHFTextField
                            name="name"
                            className="w-full"
                            variant="outlined"
                            size="small"
                            placeholder="Block Name"
                            label="Block Name"
                        />

                        <ValidationField name="validate" />
                        <Divider />
                        <ValidationField name="validateWith" />
                    </Stack>
                </Box>
            </FormProvider>
        </>
    );
}
