import { PropsWithChildren } from "react";
import {
    DefaultNodeType,
    EdgeData,
    GroupNodeType,
    MultiResponseBlockData,
    MultipleResponseNode,
    SingleResponseNode,
} from "../../types/bert";
import {
    BuildingBlockType,
    MultiResponseNodeType,
    SingleResponseBlockType,
} from "../context/NodeStateContext";
import AttributeSvg from "../svg/AttributeSvg";
import MomentSvg from "../svg/MomentSvg";
import ParameterSvg from "../svg/ParameterSvg";
import PredefinedFuncSvg from "../svg/PredefinedFuncSvg";

import {
    Node,
    NodeTypes,
    Edge,
    Viewport,
    OnNodesChange,
    OnEdgesChange,
    EdgeTypes,
    Position,
} from "reactflow";
import CreateSingleResponseNode from "../components/SingleResponse/CreateSingleResponseNode";
import CreateMultipleResponseNode from "../components/MultipleResponse/CreateMultipleResponseNode";
import SingleBlockNode from "../components/SingleResponse/SingleBlockNode";
import MultiResponseNode from "../components/MultipleResponse/MultiResponseNode";
import GroupBlockNode from "../components/SingleResponse/GroupBlockNode";
import LogicOperatorEdge from "../components/Edges/LogicOperatorEdge";
import CustomDeleteEdge from "../components/Edges/CustomDeleteEdge";
import DefaultBlockNode from "../components/SingleResponse/DefaultBlockNode";
import ValidateInfoSvg from "../svg/ValidateInfoSvg";

export interface IReactFlowStateContext {
    nodes: Node[];
    edges: Edge[];
    defaultViewport: Viewport;
    deleteNode: (id: string) => void;
    updateEdge: (e: { id: string; data: EdgeData }) => void;
    addEdge: (e: {
        target: string;
        source: string;
        block: string;
        direction?: Position;
    }) => void;
    onNodesChange: OnNodesChange;
    onEdgesChange: OnEdgesChange;
    setNodes: React.Dispatch<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.SetStateAction<Node<any, string | undefined>[]>
    >;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
    createSingleResponseNode: <
        T extends
            | SingleResponseNode<NonNullable<SingleResponseBlockType>>
            | GroupNodeType
            | DefaultNodeType
    >(e: {
        data: T;
        sourceId?: string;
        direction?: Position;
        blockParentId?: string;
    }) => string;
    updateSingleResponseNode: <
        T extends SingleResponseNode<
            NonNullable<SingleResponseBlockType>
        >["metadata"]
    >(e: {
        data: T;
        sourceId?: string;
        blockParentId?: string;
    }) => void;
    createMultipleResponseNode: <
        T extends MultipleResponseNode<MultiResponseNodeType>
    >(e: {
        data: T;
        sourceId?: string;
        blockId?: string;
        direction?: Position;
    }) => string;
    updateMultipleResponseNode: (e: {
        data: MultiResponseBlockData<MultiResponseNodeType>;
        sourceId: string;
    }) => void;
    isGroupBlockTree?: boolean;
    isBlockSource: (e: string) => boolean;
    isDefaultRuleConfig?: boolean;
    isSaving: boolean;
    handleSetIsSaving: () => void;
}

export const enum BertNodeKeys {
    createSingleResponseNode = "create-single-response-node",
    createMultiResponseNode = "create-multiple-repsonse-node",
    singleBlockNode = "single-block-node",
    groupBlockNode = "group-block-node",
    defaultBlockNode = "default-block-node",
    conditionalNode = "conditional-node",
    conditionalGptNode = "conditional-gpt-node",
    responseNode = "response-node",
}

export const enum BertEdgeKeys {
    logicOperatorEdge = "logic-edge",
    customDeleteEdge = "delete-edge",
}

export type BlockType<
    T extends
        | SingleResponseBlockType
        | BuildingBlockType
        | MultiResponseNodeType
> = {
    name: T;
    description: string;
    displayName: string;
    svgIcon: JSX.Element;
};

export const BlockTypes: Array<BlockType<SingleResponseBlockType>> = [
    {
        name: "attribute",
        displayName: "Attribute",
        description: "Tags, Types & Conv. type",
        svgIcon: <AttributeSvg />,
    },
    {
        name: "moment",
        displayName: "Moment",
        description: " Configure your Phrases",
        svgIcon: <MomentSvg />,
    },
    {
        name: "parameter",
        displayName: "Parameter",
        description: "Existing Parameter logic",
        svgIcon: <ParameterSvg />,
    },
    {
        name: "function",
        displayName: "Predefined functions",
        description: "Choose Advanced rules",
        svgIcon: <PredefinedFuncSvg />,
    },
    {
        name: "validateInfo",
        displayName: "Validate Information",
        description: "Checking Info correctness",
        svgIcon: <ValidateInfoSvg />,
    },
];

export const predefinedFuctionOptions = [
    {
        id: "dead_air",
        label: "Dead Air",
    },
    {
        id: "interruption",
        label: "Interruption",
    },
    {
        id: "rate_of_speech",
        label: "Rate of speech",
    },
    {
        id: "blank_call",
        label: "Blank Call",
    },
];

export const participantOption = [
    {
        id: "anyone",
        label: "Anyone",
    },
    {
        id: "client",
        label: "Client",
    },
    {
        id: "owner",
        label: "Rep",
    },
];

export type ReactFlowStateProviderProps = PropsWithChildren<{
    isGroupBlockTree?: boolean;
    state?: {
        nodes: Node[];
        edges: Edge[];
    };
}>;

export const nodeTypes: NodeTypes = {
    [BertNodeKeys.createSingleResponseNode]: CreateSingleResponseNode,
    [BertNodeKeys.createMultiResponseNode]: CreateMultipleResponseNode,
    [BertNodeKeys.singleBlockNode]: SingleBlockNode,
    [BertNodeKeys.conditionalNode]: MultiResponseNode,
    [BertNodeKeys.responseNode]: MultiResponseNode,
    [BertNodeKeys.defaultBlockNode]: DefaultBlockNode,
    [BertNodeKeys.groupBlockNode]: GroupBlockNode,
    [BertNodeKeys.conditionalGptNode]: MultiResponseNode,
};
export const edgeTypes: EdgeTypes = {
    [BertEdgeKeys.logicOperatorEdge]: LogicOperatorEdge,
    [BertEdgeKeys.customDeleteEdge]: CustomDeleteEdge,
};
